var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "navbar-container" }, [_c("Navbar")], 1),
      _c(
        "section",
        { staticClass: "section-wrapper" },
        [
          _vm.projectDetails == null ? _c("Loader") : _vm._e(),
          _vm.projectDetails
            ? _c("div", { staticClass: "page-wrapper" }, [
                _c(
                  "div",
                  { staticClass: "page-call-container" },
                  [
                    _c("center", [
                      _c("div", { staticClass: "row select-team-row" }, [
                        _c("div", { staticClass: "col-9" }, [
                          _c("div", { staticClass: "bold-text-head" }, [
                            _vm._v("Priority Access"),
                          ]),
                          _c("div", { staticClass: "light-text-head" }, [
                            _vm._v(
                              "\n                You have been given priority access to the teams in the Grupa\n                ecosystem\n              "
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "col-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "btn grupa-blue-btn btn-lg proceedBtn",
                              staticStyle: {
                                float: "none",
                                "margin-top": "0px",
                                "margin-bottom": "0px",
                                cursor: "pointer",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTeam()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                Choose a Team\n                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c(
                      "CalendlyModal",
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.projectDetails
                                  .preview_meeting_start_date === null,
                              expression:
                                "projectDetails.preview_meeting_start_date === null",
                            },
                          ],
                          staticStyle: { display: "none" },
                          attrs: { firstMeeting: true },
                          on: { closeModal: _vm.closeCalendly },
                        },
                        "CalendlyModal",
                        { user: _vm.currentUser, meetLink: _vm.meetLink },
                        false
                      )
                    ),
                    _vm.projectDetails.preview_meeting_start_date
                      ? _c("div", { staticClass: "meeting-details" }, [
                          _c("img", {
                            attrs: { src: "/img/onboarding/schedule-icon.svg" },
                          }),
                          _c("div", { staticClass: "meeting-time" }, [
                            _vm._v(
                              "\n            Meeting scheduled for\n            "
                            ),
                            _c("span", {}, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm
                                      .$moment(
                                        _vm.projectDetails
                                          .preview_meeting_start_date
                                      )
                                      .format("dddd, MMMM Do YYYY, h:mm:ss a")
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "meeting-link" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  target: "_blank",
                                  href: _vm.projectDetails
                                    .preview_meeting_hangout_link,
                                },
                              },
                              [
                                _c("div", { staticClass: "join-meeting-div" }, [
                                  _vm._v("Join Meeting"),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.isMeetingSuccess
        ? _c(
            "MeetingSuccess",
            _vm._b(
              { attrs: { closeModal: _vm.closeSuccessModal } },
              "MeetingSuccess",
              {
                meetingLoader: _vm.meetingLoader,
                meetingPayload: _vm.meetingPayload,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }