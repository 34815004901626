<template>
  <div>
    <div class="navbar-container">
      <Navbar />
    </div>
    <section class="section-wrapper">
      <Loader v-if="projectDetails == null" />
      <div v-if="projectDetails" class="page-wrapper">
        <div class="page-call-container">
          <!-- <div class="img-container">
            <img src="/img/onboarding/schedule-img.svg" alt="" />
          </div> -->
          <!-- <div class="page-header-title">
            Your product needs are now our priority!
          </div>
          <div class="page-sub-header">
            Schedule a call with us to discuss our suggestions on the team that
            fit you best, and other ways we can help.
          </div> -->
          <!-- <div
            class="calendly-container"
            
          > -->
          <center>
            <div class="row select-team-row" style="">
              <div class="col-9">
                <div class="bold-text-head">Priority Access</div>
                <div class="light-text-head">
                  You have been given priority access to the teams in the Grupa
                  ecosystem
                </div>
              </div>

              <div class="col-3">
                <div
                  @click="selectTeam()"
                  style="
                    float: none;
                    margin-top: 0px;
                    margin-bottom: 0px;
                    cursor: pointer;
                  "
                  class="btn grupa-blue-btn btn-lg proceedBtn"
                >
                  Choose a Team
                  <!-- {{ user_type }} -->
                </div>
              </div>
            </div>
          </center>

          <CalendlyModal
            style="display: none"
            v-show="projectDetails.preview_meeting_start_date === null"
            v-on:closeModal="closeCalendly"
            v-bind:firstMeeting="true"
            v-bind="{ user: currentUser, meetLink }"
          />
          <!-- </div> -->
          <!-- <div
            
            class="calendly-container"
            @click="handleScheduleMeeting"
          >
            <div class="click-call-schedule">Click And Schedule Meeting</div>
          </div> -->
          <!--  -->
          <div
            v-if="projectDetails.preview_meeting_start_date"
            class="meeting-details"
          >
            <img src="/img/onboarding/schedule-icon.svg" />
            <div class="meeting-time">
              Meeting scheduled for
              <span style="">
                {{
                  $moment(projectDetails.preview_meeting_start_date).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )
                }}
              </span>
            </div>
            <div class="meeting-link">
              <a
                target="_blank"
                :href="projectDetails.preview_meeting_hangout_link"
              >
                <div class="join-meeting-div">Join Meeting</div>
              </a>
            </div>
          </div>
          <!-- <div class="meeting-objective-container">
            <div class="meeting-header">MEETING OBJECTIVES</div>
            <div
              v-for="(meeting, index) in meetingAgenda"
              :key="index"
              class="meeting-agenda-list"
            >
              <img src="/img/onboarding/checkmark-circle.svg" alt="" />
              {{ meeting }}
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <!-- v-if="showCalendly" -->

    <MeetingSuccess
      v-if="isMeetingSuccess"
      v-bind:closeModal="closeSuccessModal"
      v-bind="{ meetingLoader, meetingPayload }"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixPanel from "@/mixins/mixPanel";
import Navbar from "@/components/Navbar/navbar";
import Loader from "@/components/general/loader";
import { completeProjectDetails } from "@/api";
import CalendlyModal from "@/components/calendlyScheduler.vue";
import { teamPreviewMeeting } from "@/api";
import MeetingSuccess from "@/components/meetingSuccess";
import { calendlyEndpoint } from "@/api/calendlyApi";

export default {
  mixins: [mixPanel],
  props: {},
  components: {
    Navbar,
    CalendlyModal,
    MeetingSuccess,
    Loader,
  },

  data: () => ({
    meetingAgenda: [
      "Learn about Grupa marketplace.",
      "Share your product & team requirements.",
      "Get matched with the best-fit team.",
    ],
    openDialog: false,
    showCalendly: false,
    meetingLoader: true,
    meetingPayload: null,
    isMeetingSuccess: false,
    dialogSpinner: false,
    userTimeZone: "",
    loadCalendly: true,
    //meetLink: "https://calendly.com/grupa-shany/intro",
    meetLink: "https://calendly.com/grupa/30min",

    projectDetails: null,
  }),
  mounted() {
    const id = this.$route.params.id;
    this.getClientProject(id);
  },
  created() {
    window.addEventListener("message", async (e) => {
      if (this.isCalendlyEvent(e)) {
        this.$developConsole("Event Scheduled", e.data);
        // calendly.event_scheduled
        //  calendly.date_and_time_selected
        if (e.data.event == "calendly.event_scheduled") {
          this.showCalendly = false;
          this.isMeetingSuccess = true;
          let uri = e.data.payload.event.uri;
          // await this.getUserMeeting();
          await this.fetchMeetingObject(uri);
        }
      }
    });
  },
  destroyed() {
    window.removeEventListener("message", function (e) {});
  },
  methods: {
    selectTeam() {
      let project_id = this.$route.params.id;
     // alert("sdsd");
      this.$router.push({
        name: "select_team",
        params: { project_id },
      });
    },
    getClientProject(id) {
      completeProjectDetails(id)
        .then((res) => {
          if (res.data.project.workspace_progress_level < 1) {
            const id = this.$route.params.id;
            this.$router.push({
              name: "reserve_team",
              params: { id },
            });
          } else {
            this.projectDetails = res.data.project;
          }
        })
        .catch((err) => {});
    },
    closeDialog() {
      this.openDialog = false;
    },
    closeCalendly() {
      this.showCalendly = false;
    },
    isCalendlyEvent: function (e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    },
    closeSuccessModal() {
      if (!this.meetingLoader) {
        this.isMeetingSuccess = false;
        const id = this.$route.params.id;
        this.$router.push({
          name: "project_workspace",
          params: { id },
        });
      }
    },
    handleScheduleMeeting() {
      this.showCalendly = true;
    },
    async fetchMeetingObject(url) {
      await calendlyEndpoint(url, "founder")
        .then((response) => {
          if (response.status == 200) {
            let { end_time, start_time, event_guests, location, created_at } =
              response.data.resource;
            let payload = {
              meeting_attendees: event_guests,
              meeting_end: end_time,
              hangout_link: location.join_url,
              meeting_id: "",
              meeting_start_date: start_time,
              meeting_summary: "",
              meeting_created: "",
            };
            this.meetingPayload = payload;
            // Save meeting endpoint
            this.scheduleTeamPreviewMeeting(response.data.resource);
            this.mixPanelTrack({
              event: "ClientScheduleFirstMeeting",
            });
          } else {
            this.meetingLoader = false;
            this.mixPanelTrack({
              event: "ScheduleMeetingFailed",
              type: "client",
            });
            throw new Error(resp.data.msg);
          }
        })
        .catch((error) => {
          this.meetingLoader = false;
        });
    },
    scheduleTeamPreviewMeeting(payload) {
      let { end_time, start_time, event_guests, location, created_at } =
        payload;
      let data = {
        preview_meeting_attendees: event_guests,
        preview_meeting_created: "",
        preview_meeting_end: end_time,
        preview_meeting_hangout_link: location.join_url,
        preview_meeting_id: "",
        preview_meeting_start_date: start_time,
        preview_meeting_summary: "",
        project_id: +this.$route.params.id,
      };
      teamPreviewMeeting(data)
        .then((resp) => {
          this.meetingLoader = false;
        })
        .catch((err) => {
          this.meetingLoader = false;
        });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),
    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
.select-team-row {
  background-color: black;
  width: 50%;
  margin: 40px;
  padding: 20px;
  margin-bottom: -10px;
}
.light-text-head {
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;

  text-align: left;
}
.bold-text-head {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #ffffff;
  text-align: left;
}
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.navbar-container {
  position: fixed;
  width: 100%;
}
.page-wrapper {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendly-container {
  width: 800px;
}
.page-call-container {
  width: 100%;
  /* margin-top: 48px; */
  text-align: center;
}
.page-header-title {
  width: 438px;

  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
  margin-top: 48px;
}
.page-sub-header {
  width: 438px;

  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #53585f;
  margin-top: 16px;
}
.calendly-container {
  height: 221px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.click-call-schedule {
  font-weight: 900;
  font-size: 23px;
  line-height: 38px;
  display: flex;
  align-items: center;
  /* color: #e4e5e7; */
  color: #1b1e22;
}
.meeting-objective-container {
  margin: 48px 0px;
}
.meeting-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-bottom: 8px;
}
.meeting-agenda-list {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 8px;
}
.meeting-details {
  text-align: center;
  margin-top: 48px;
}
.meeting-time {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
}
.meeting-time span {
  color: #1b1e22;
}
.meeting-link {
  font-size: 12px;
  line-height: 140%;
  color: #c8cbd0;
  margin-top: 16px;
  display: flex;
  justify-content: center;
}
.join-meeting-div {
  border-radius: 3px;
  padding: 16px 20px;
  font-size: 14px;
  line-height: 100%;
  background: #0781f2 !important;
  color: #fdfdfd !important;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  width: 146px;
  margin: 24px;
}
.section-wrapper {
  margin-top: 80px;
}
</style>
